exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pastprojects-hardware-js": () => import("./../../../src/pages/pastprojects/hardware.js" /* webpackChunkName: "component---src-pages-pastprojects-hardware-js" */),
  "component---src-pages-pastprojects-hw-build-js": () => import("./../../../src/pages/pastprojects/hw/build.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-build-js" */),
  "component---src-pages-pastprojects-hw-cobbling-js": () => import("./../../../src/pages/pastprojects/hw/cobbling.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-cobbling-js" */),
  "component---src-pages-pastprojects-hw-dns-ip-js": () => import("./../../../src/pages/pastprojects/hw/dns-ip.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-dns-ip-js" */),
  "component---src-pages-pastprojects-hw-ethernet-js": () => import("./../../../src/pages/pastprojects/hw/ethernet.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-ethernet-js" */),
  "component---src-pages-pastprojects-hw-raspi-js": () => import("./../../../src/pages/pastprojects/hw/raspi.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-raspi-js" */),
  "component---src-pages-pastprojects-hw-routers-js": () => import("./../../../src/pages/pastprojects/hw/routers.js" /* webpackChunkName: "component---src-pages-pastprojects-hw-routers-js" */),
  "component---src-pages-pastprojects-index-js": () => import("./../../../src/pages/pastprojects/index.js" /* webpackChunkName: "component---src-pages-pastprojects-index-js" */),
  "component---src-pages-pastprojects-internet-js": () => import("./../../../src/pages/pastprojects/internet.js" /* webpackChunkName: "component---src-pages-pastprojects-internet-js" */),
  "component---src-pages-pastprojects-software-js": () => import("./../../../src/pages/pastprojects/software.js" /* webpackChunkName: "component---src-pages-pastprojects-software-js" */),
  "component---src-pages-pastprojects-sw-phpsql-js": () => import("./../../../src/pages/pastprojects/sw/phpsql.js" /* webpackChunkName: "component---src-pages-pastprojects-sw-phpsql-js" */),
  "component---src-pages-pastprojects-sw-websites-js": () => import("./../../../src/pages/pastprojects/sw/websites.js" /* webpackChunkName: "component---src-pages-pastprojects-sw-websites-js" */),
  "component---src-pages-pastprojects-www-protocols-js": () => import("./../../../src/pages/pastprojects/www/protocols.js" /* webpackChunkName: "component---src-pages-pastprojects-www-protocols-js" */),
  "component---src-pages-pastprojects-www-security-js": () => import("./../../../src/pages/pastprojects/www/security.js" /* webpackChunkName: "component---src-pages-pastprojects-www-security-js" */),
  "component---src-pages-projects-beer-js": () => import("./../../../src/pages/projects/beer.js" /* webpackChunkName: "component---src-pages-projects-beer-js" */),
  "component---src-pages-projects-drones-js": () => import("./../../../src/pages/projects/drones.js" /* webpackChunkName: "component---src-pages-projects-drones-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-keezer-js": () => import("./../../../src/pages/projects/keezer.js" /* webpackChunkName: "component---src-pages-projects-keezer-js" */),
  "component---src-pages-projects-weather-js": () => import("./../../../src/pages/projects/weather.js" /* webpackChunkName: "component---src-pages-projects-weather-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

